import { createSlice } from "@reduxjs/toolkit";
import {
    getStrategy,
    getStrategyAll,
    getStrategyDetailsById,
    setStrategyActive,
    setStrategyAlertAverage,
    setStrategyAlertLastWin
} from "./thunk";

interface strategyStateType {
    strategies: Array<any>;
    error?: string | object | null | undefined | unknown;
    loading: boolean;
    strategyByIdData?: Array<any>;
    loadingStrategyModal?: boolean;
    stopLoadingStrategy?: boolean;
}
export const initialState: strategyStateType = {
    strategies: [],
    error: null,
    loading: false,
    strategyByIdData: [],
    loadingStrategyModal: false,
    stopLoadingStrategy: false
};

const strategySlice = createSlice({
    name: "strategy",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(getStrategyAll.pending, (state: any, ) => {
            state.loading = true;
        });

        builder.addCase(getStrategyAll.fulfilled, (state: any, action: any) => {
            if(state.stopLoadingStrategy){
                state.stopLoadingStrategy = false
            }else{
                state.strategies = action.payload.data;
                state.loading = false;
            }

        });

        builder.addCase(getStrategyAll.rejected, (state, action) => {
            state.error = action.payload || null;
            state.loading = false;
        });



        builder.addCase(getStrategy.pending, (state: any, ) => {
            state.loading = true;
        });


        builder.addCase(getStrategy.fulfilled, (state: any, action: any) => {
            state.strategies = action.payload.data;
            state.loading = false;
        });

        builder.addCase(getStrategy.rejected, (state, action) => {
            state.loading= false;
        });



        builder.addCase(getStrategyDetailsById.pending, (state: any, ) => {
            state.loadingStrategyModal = true;
        });

        builder.addCase(getStrategyDetailsById.fulfilled, (state: any, action: any) => {
            state.strategyByIdData = action.payload.data.results ?? [];
            state.loadingStrategyModal = false;
        });

        builder.addCase(getStrategyDetailsById.rejected, (state, action) => {
            state.loadingStrategyModal = true;
        });



        builder.addCase(setStrategyActive.pending, (state: any, ) => {
            state.stopLoadingStrategy = true;
        });

        builder.addCase(setStrategyActive.fulfilled, (state: any, action: any) => {
            state.stopLoadingStrategy = false;
        });

        builder.addCase(setStrategyActive.rejected, (state, action) => {
            state.stopLoadingStrategy = false;
        });


        builder.addCase(setStrategyAlertLastWin.pending, (state: any, ) => {
            state.stopLoadingStrategy = true;
        });

        builder.addCase(setStrategyAlertLastWin.fulfilled, (state: any, action: any) => {
            state.stopLoadingStrategy = false;
        });

        builder.addCase(setStrategyAlertLastWin.rejected, (state, action) => {
            state.stopLoadingStrategy = false;
        });


        builder.addCase(setStrategyAlertAverage.pending, (state: any, ) => {
            state.stopLoadingStrategy = true;
        });

        builder.addCase(setStrategyAlertAverage.fulfilled, (state: any, action: any) => {
            state.stopLoadingStrategy = false;
        });

        builder.addCase(setStrategyAlertAverage.rejected, (state, action) => {
            state.stopLoadingStrategy = false;
        });

    },
});

export default strategySlice.reducer;
