import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import DashboardAnalytics from "../Dashboard/DashboardAnalytics";
import {withTranslation} from "react-i18next";

const index = (props: any) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Analytics")} | {props.t("Top Bet Football")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboards" breadcrumbItem={props.t("Analytics")}/>
                    <Row>
                        <Col xl={12}>
                            <DashboardAnalytics />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(index);
