import { createAsyncThunk } from "@reduxjs/toolkit";
import * as url from "../../helpers/url_helper";
import {ApiCore} from "../../helpers/api_helper";
import {GET_MESSAGES_UNREAD_COUNT} from "../../helpers/url_helper";
const api = new ApiCore();

export const getMessages = createAsyncThunk(
    "user/message",
    async () => {
        try {
            return api.get(
                url.GET_MESSAGES
            );
        } catch (error) {
            return error;
        }
    }
);

export const getMessagesUnreadCount = createAsyncThunk(
    "user/message-unread-count",
    async () => {
        try {
            return api.get(
                url.GET_MESSAGES_UNREAD_COUNT
            );
        } catch (error) {
            return error;
        }
    }
);
