
//Include Both Helper File with needed methods
import {ApiCore} from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";

import {createAsyncThunk} from "@reduxjs/toolkit";

const api = new ApiCore();

export const userForgetPassword = createAsyncThunk(
    "user/recover/password",
    async (user: any) => {
    try {
        const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
        return api.create(
            url.POST_PASSWORD_FORGET + '?lang=' + lang,
            {
                email: user.email
            }
        );
    } catch (error) {
        return error;
    }
});
