import { createSlice } from "@reduxjs/toolkit";
import {getSubscription, getActivateSubscription, getCancelSubscription, updateUserDetails} from "./thunk";

interface ProfileStateType {
    loadingSubscription?: boolean,
    error?: string | object | null | undefined | unknown,
    success?: any,
    subscription?: any,
    subscriptionLink?: string,
    loadingActivateOrCancelSubscription?: boolean,
    cancelSubscriptionStatus?: string,
    loadingUpdateUserDetails?: boolean,
    messageUpdateUserDetails?: any,
    isErrorUpdateUserDetails?: boolean
}
export const initialState: ProfileStateType = {
    loadingSubscription: false,
    error: "",
    success: "",
    subscription: null,
    subscriptionLink: "",
    loadingActivateOrCancelSubscription: false,
    cancelSubscriptionStatus: "",
    loadingUpdateUserDetails: false,
    messageUpdateUserDetails: null,
    isErrorUpdateUserDetails: false
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(getSubscription.pending, (state: any, ) => {
            state.loadingSubscription = true;
        });

        builder.addCase(getSubscription.fulfilled, (state: any, action: any) => {
            state.loadingSubscription = false;
            state.subscription = action.payload.data
        });

        builder.addCase(getSubscription.rejected, (state) => {
            state.loadingSubscription = false;
        });



        builder.addCase(getActivateSubscription.pending, (state: any, ) => {
            state.loadingActivateOrCancelSubscription = true;
        });

        builder.addCase(getActivateSubscription.fulfilled, (state: any, action: any) => {
            state.loadingActivateOrCancelSubscription = false;
            window.open(action.payload.data.link, '_blank');
        });

        builder.addCase(getActivateSubscription.rejected, (state) => {
            state.loadingActivateOrCancelSubscription = false;
        });



        builder.addCase(getCancelSubscription.pending, (state: any, ) => {
            state.cancelSubscriptionStatus = ''
            state.loadingActivateOrCancelSubscription = true;
        });

        builder.addCase(getCancelSubscription.fulfilled, (state: any, action: any) => {
            state.cancelSubscriptionStatus = 'success'
            state.loadingActivateOrCancelSubscription = false;
        });

        builder.addCase(getCancelSubscription.rejected, (state) => {
            state.cancelSubscriptionStatus = 'failed'
            state.loadingActivateOrCancelSubscription = false;
        });




        builder.addCase(updateUserDetails.pending, (state: any, ) => {
            state.loadingUpdateUserDetails = true
            state.isErrorUpdateUserDetails = false
        });

        builder.addCase(updateUserDetails.fulfilled, (state: any, action: any) => {
            state.loadingUpdateUserDetails = false
            state.messageUpdateUserDetails = action.payload.message
            state.isErrorUpdateUserDetails = false
        });

        builder.addCase(updateUserDetails.rejected, (state:any, action:any) => {
            state.loadingUpdateUserDetails = false
            state.messageUpdateUserDetails = action.error.message || null;
            state.isErrorUpdateUserDetails = true
        });

    },
});


export default profileSlice.reducer;
