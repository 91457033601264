import { createSlice } from "@reduxjs/toolkit";
import { userForgetPassword } from "./thunk";
interface ForgotPasswordStateType {
    forgetError?: string | object | null | undefined | unknown,
    forgetLoading?: boolean
}
export const initialState: ForgotPasswordStateType = {
    forgetError: '',
    forgetLoading: false
};

const forgotPasswordSlice = createSlice({
    name: "forgotpwd",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(userForgetPassword.pending, (state: any) => {
            state.forgetLoading = true;
        });
        builder.addCase(userForgetPassword.fulfilled, (state: any) => {
            state.forgetLoading = false;
        });
        builder.addCase(userForgetPassword.rejected, (state, action) => {
            state.forgetError = action.error.message || null;
            state.forgetLoading = false;
        });
    }
});

export default forgotPasswordSlice.reducer;
