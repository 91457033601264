import usFlag from "../assets/images/flags/us.jpg";
import ptFlag from "../assets/images/flags/portuguese.jpg";

const languages : Object = {
  pt: {
    label: "Portuguese",
    flag: ptFlag
  },
  en: {
    label: "English",
    flag: usFlag
  },
};

export default languages;
