import { createAsyncThunk } from "@reduxjs/toolkit";
import * as url from "../../helpers/url_helper";
import {ApiCore} from "../../helpers/api_helper";
const api = new ApiCore();
export const getGames = createAsyncThunk(
  "data/table-result",
  async (params: {game_closed: number, home_score: any, away_score: any,
                  time_start_score: any, time_end_score: any,
                  time_start: any, time_end: any,
                  home_score_status: any, away_score_status: any,
                  goals: any, goals_status: any,
                  goals_exact: any,

                  hi_start: any,
                  hi_end: any,
                  ai_start: any,
                  ai_end: any,

                  hc_start: any,
                  hc_end: any,
                  ac_start: any,
                  ac_end: any,

                  hi_start_status: any,
                  hi_end_status: any,
                  ai_start_status: any,
                  ai_end_status: any,

                  hc_start_status: any,
                  hc_end_status: any,
                  ac_start_status: any,
                  ac_end_status: any,

                  start_updated_at: any, end_updated_at: any,
                  competition: any,
                  team: any

  }) => {
      try {
          const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
          let search = '?lang='+lang +'&page=1&length=300&orderBy=time_game&by=DESC'

          if(params.home_score > -1){
              search += '&home_score=' + params.home_score
          }

          if(params.away_score > -1){
              search += '&visitant_score=' + params.away_score
          }

          if(params.time_start_score > -1){
              search += '&minute_score_start=' + params.time_start_score
          }

          if(params.time_end_score > -1){
              search += '&minute_score_end=' + params.time_end_score
          }

          if(params.time_start > -1){
              search += '&minute_start=' + params.time_start
          }

          if(params.time_end > -1){
              search += '&minute_end=' + params.time_end
          }

          if(params.goals > -1){
              search += '&goals=' + params.goals
          }

          if(params.home_score_status != null){
              search += '&home_score_status=' + params.home_score_status.value
          }

          if(params.away_score_status != null){
              search += '&away_score_status=' + params.away_score_status.value
          }

          if(params.goals_status != null){
              search += '&goals_status=' + params.goals_status.value
          }

          if(params.start_updated_at != ''){
              search += '&start_updated_at=' + params.start_updated_at
          }

          if(params.end_updated_at != ''){
              search += '&end_updated_at=' + params.end_updated_at
          }

          if(params.competition.length > 0){
              search += '&competition=' + params.competition.map( function (data: any){
                    return ',' + data
              })
          }

          if(params.goals_exact.home != '' && params.game_closed == 1){
              search += '&goals_home_exact=' + params.goals_exact.home
              search += '&goals_away_exact=' + params.goals_exact.away
          }


          if(params.hi_start > -1){
              search += '&hi_start=' + params.hi_start
          }

          if(params.hi_start_status != null){
              search += '&hi_start_status=' + params.hi_start_status.value
          }

          if(params.hi_end > -1){
              search += '&hi_end=' + params.hi_end
          }

          if(params.hi_end_status != null) {
              search += '&hi_end_status=' + params.hi_end_status.value
          }



          if(params.hc_start > -1){
              search += '&hc_start=' + params.hc_start
          }

          if(params.hc_start_status != null){
              search += '&hc_start_status=' + params.hc_start_status.value
          }

          if(params.hc_end > -1){
              search += '&hc_end=' + params.hc_end
          }



          if(params.ai_start > -1){
              search += '&ai_start=' + params.ai_start
          }

          if(params.ai_start_status != null){
              search += '&ai_start_status=' + params.ai_start_status.value
          }

          if(params.ai_end > -1){
              search += '&ai_end=' + params.ai_end
          }

          if(params.ai_end_status != null) {
              search += '&ai_end_status=' + params.ai_end_status.value
          }



          if(params.ac_start > -1){
              search += '&ac_start=' + params.ac_start
          }

          if(params.ac_start_status != null){
              search += '&ac_start_status=' + params.ac_start_status.value
          }

          if(params.ac_end > -1){
              search += '&ac_end=' + params.ac_end
          }

          if(params.ac_end_status != null) {
              search += '&ac_end_status=' + params.ac_end_status.value
          }

          if(params.team != ''){
              search += '&team=' + params.team
          }

          search += '&game_closed=' + params.game_closed

          return api.get(
              url.GET_GAMES + search
          );
      } catch (error) {
          return error;
      }
  }
);

export const getTeam = createAsyncThunk(
    "data/team",
    async (params: {team: string}) => {
        try {

            let search = ''

            if(params.team != ''){
                search += '?team=' + params.team
            }

            return api.get(
                url.GET_TEAM + search
            );

        } catch (error) {
            return error;
        }
    }
);
