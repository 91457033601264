import React, {useEffect, useState} from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {getMessages} from "../../slices/Messages/thunk";
import * as constant from "../../constants/global";

const BasicTable = (props: any) => {

  const dispatch = useDispatch();

  // @ts-ignore
  const [favoriteGames, setFavoriteGame] = useState(localStorage.getItem(constant.favorite_games) != null ? JSON.parse(localStorage.getItem(constant.favorite_games)) : [])

  const setFavoriteFilter = (event: string) => {

    // @ts-ignore
    let favorite_game_set = localStorage.getItem(constant.favorite_games) != null ? JSON.parse(localStorage.getItem(constant.favorite_games)) : []

    if(favorite_game_set == null){
      localStorage.setItem(constant.favorite_games, JSON.stringify([event]))
      setFavoriteGame([event])
    }else{

      const index_array = favorite_game_set.indexOf(event)
      if(index_array > -1){
        favorite_game_set.splice(index_array, 1)
      }else{
        favorite_game_set.push(event)
      }
      setFavoriteGame(favorite_game_set)
      localStorage.setItem(constant.favorite_games, JSON.stringify(favorite_game_set))
    }
  }

  const checkFavoriteGame = (event: string) => {

    // @ts-ignore
    let favorite_game_set = localStorage.getItem(constant.favorite_games) != null ? JSON.parse(localStorage.getItem(constant.favorite_games)) : []

    const index_array = favorite_game_set.indexOf(event)
    return index_array > -1;

  }

  const {messages, loading} = useSelector((state: any) => ({
    messages: state.messages.messages.length > 0 ?
        state.messages.messages.map( (message: any) =>
            {
              // @ts-ignore
              return ({
                'description': message.description,
                'colorBG': message.colorBG,
                'event_id': message.event_id,
                'goals_home': message.goals_home,
                'home': message.home,
                'home_score': message.home_score,
                'away_score': message.away_score,
                'away': message.away,
                'goals_away': message.goals_away,
                'game_link': message.game_link,
                'created_at': message.created_at,
                'read': parseInt(message.read)
              });
            }
        )
        : [],
    error: state.messages.error,
    loading: state.messages.loading

  }));

  useEffect(() => {
    dispatch(getMessages());
  }, [])

  return (
    <Col>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="justify-content-between d-flex align-items-center">
              <h4 className="card-title">{props.t("Last 30 messages")}</h4>
            </CardHeader>
            <CardBody>
              {
                messages.length > 0 &&
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>{props.t("Strategy")}</th>
                        <th>{props.t("Home")}</th>
                        <th>{props.t("Goals")}</th>
                        <th></th>
                        <th></th>
                        <th>{props.t("Goals")}</th>
                        <th>{props.t("Away")}</th>
                        <th>{props.t("Created at")}</th>
                        <th className="text-center">{props.t("Read")}</th>
                        <th className="text-center">{props.t("Favorite")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (messages).map((m: any, key: number) => (
                            <tr  style={{color: m.colorBG}} key={'message_' + key}>
                              <th className="text-white text-center" style={{backgroundColor: m.colorBG}}  scope="row">{key+1}</th>
                              <td>{m.description}</td>
                              <td>{m.home}</td>
                              <td>{m.goals_home}</td>
                              <td className="text-center">{m.home_score}</td>
                              <td className="text-center">{m.away_score}</td>
                              <td>{m.goals_away}</td>
                              <td>{m.away}</td>
                              <td>{m.created_at}</td>
                              <td className="text-center font-size-20"><i className={'mdi ' + (m.read == 0 ? 'mdi-eye-off clr-red' : 'mdi mdi-eye-check clr-green')}></i></td>
                              <td className="text-center font-size-20"><i onClick={ ()=> setFavoriteFilter(m.home + ' - ' + m.away)}
                                     className={"mdi mdi-star" + (checkFavoriteGame(m.home + ' - ' + m.away) ? '' : '-outline') + " clr-red cursor-pointer"}></i></td>
                            </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              }
              {
                  messages.length == 0 && !loading &&
                  <p>{props.t("No messages available")}</p>
              }
              {
                  loading &&
                  <p>{props.t("Loading messages...")}</p>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default withTranslation()(BasicTable);
