import React, {useEffect, useState} from "react";
import {
    Card, CardBody, Col, Input, Row,
    Spinner,
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {
    getStrategyAll,
    getStrategyDetailsById,
    setStrategyActive,
    setStrategyAlertLastWin,
    setStrategyAlertAverage,
    setStrategyAlertStatusLastWin,
    setStrategyAlertStatusAverage,
    setStrategyAlertTime
} from "../../slices/Strategy/thunk";
import {withTranslation} from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment";
import * as constant from "../../constants/global";

const Summary = (props : any) => {

    const listStatus = [
        { label: '=', value: 0},
        { label: '>', value: 1},
        { label: '<', value: 2}
    ]

    const dispatch = useDispatch();

    const {error, loading, strategies, loadingStrategyModal, strategyByIdData} = useSelector((state: any) => ({
        error: state.strategy.error,
        loading: state.strategy.loading,
        strategies: state.strategy.strategies.filter(function (strategy: any) {
            return strategy.id!== undefined;
        }),
        loadingStrategyModal: state.strategy.loadingStrategyModal,
        strategyByIdData: state.strategy.strategyByIdData
    }));

    const [strategyModal, setStrategyModal] = useState(false)
    const [strategySummary, setStrategySummary] = useState([])

    const [timeStart, setTimeStart] = useState(null)
    const [timeEnd, setTimeEnd] = useState(null)


    const updateStrategyTimeout = () => {
        dispatch(getStrategyAll());
    }

    const seeStrategy = (strategy: any) => {
        setStrategyModal(true)
        setStrategySummary(strategy)
        dispatch(getStrategyDetailsById(strategy["id"]));
    }

    const setNotificationActive = (event: any) => {
        dispatch(setStrategyActive(event.target.getAttribute("data-strategyid")));
    }

    const setAlertLastWin = (event: any) => {
        dispatch(setStrategyAlertLastWin( {alert_id : event.target.getAttribute("data-strategyid"), value: event.target.value}));
    }

    const setAlertAverage = (event: any) => {
        dispatch(setStrategyAlertAverage( {alert_id : event.target.getAttribute("data-strategyid"), value: event.target.value}));
    }

    useEffect(() => {
        dispatch(getStrategyAll());
        const timer = setInterval(updateStrategyTimeout, 30000);
        return () => clearInterval(timer);
    }, []);

    const setLastWinStatus = (row: any, event: any) => {
        dispatch(setStrategyAlertStatusLastWin( {alert_id : event.name, value: row.value}));

    }

    const setAverageStatus = (row: any, event: any) => {
        dispatch(setStrategyAlertStatusAverage( {alert_id : event.name, value: row.value}));
    }

    const setFilterTime = (when: string, event: any) => {
        dispatch(setStrategyAlertTime( {alert_id : event.target.name, when: when, time: event.target.value}));
    }


    // @ts-ignore
    return (
        <React.Fragment>
            {(strategies).map((widget: any, key: number) => (
                <Col xl={4} md={6} sm={6} key={'strategy_' + key}>
                    <Card style={{borderRadius: '10px'}}>
                        <CardBody style={{border: '2px solid ' + widget["colorBG"], borderRadius: '10px'}}>
                                <Row xs={12}>
                                    <Col xs={12}>
                                        <Row className="p-1 align-items-center">
                                            <Col xs={8}>
                                                <h6 className="font-size-xs text-uppercase" style={{color:widget["colorBG"]}}>
                                                    <span className="mdi mdi-arrow-right-bold"></span>
                                                    {widget["description"]}
                                                </h6>
                                            </Col>
                                            <Col xs={4} className="text-center">
                                                <i onClick={()=> seeStrategy(widget)} className="mdi mdi-eye see-strategy" style={{color:widget["colorBG"]}}></i>
                                            </Col>
                                        </Row>
                                        <Row className="p-1 pb-2" style={{borderBottom: '1px solid ' + widget["colorBG"]}}>
                                            <Col xs={8}>
                                                <Row className="float-end">
                                                    <Col className="p-1">
                                                        <Input name={widget["id"]}
                                                               className="form-control max-width-time-alert text-end float-end"
                                                               onChange={(event)=> setFilterTime('start', event)}
                                                               type="time"
                                                               defaultValue={widget["summary"]["start_at"] ?? ''}
                                                               key={"time-start-" + widget["id"]}
                                                               id={"time-start-" + widget["id"]}
                                                        />
                                                    </Col>
                                                    <Col className="p-1">
                                                        <Input name={widget["id"]}
                                                           className="form-control max-width-time-alert text-end float-end"
                                                           onChange={(event)=> setFilterTime('end', event)}
                                                           type="time"
                                                           defaultValue={widget["summary"]["end_at"] ?? ''}
                                                           key={"time-end-" + widget["id"]}
                                                           id={"time-end-" + widget["id"]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={4} className="text-center">
                                                <div className="form-switch form-switch-md" style={{color:widget["colorBG"]}}>
                                                    <Input
                                                        onClick={(e) => setNotificationActive(e)}
                                                        data-strategyid={widget["id"]} className="form-check-input"
                                                        type="checkbox" defaultChecked={widget["active"] == 1} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center p-1">
                                            <Col xs={6} className="text-end"  style={{borderRight: '1px solid ' + widget["colorBG"]}}>
                                                <div className="text-muted">{props.t("Last win")}</div>
                                            </Col>
                                            <Col xs={2}>
                                                <div className="text-muted">{widget["summary"]["current_last_win"]}</div>
                                            </Col>
                                            <Col xs={4}>
                                                <Row>
                                                    <Input type="number" className="form-control text-center border-0"
                                                           data-strategyid={widget["id"]}
                                                           onChange={(e) =>
                                                               setAlertLastWin(e)}
                                                           defaultValue={widget["summary"]["alert_last_win"]}/>
                                                </Row>
                                                <Row>
                                                    <Select
                                                        name={widget["id"]}
                                                        isSearchable={false}
                                                        className="select-status"
                                                        classNamePrefix="react-select"
                                                        placeholder={widget["summary"]["status_last_win"]}
                                                        options={listStatus}
                                                        //value={1}
                                                        onChange={ (row: any, event:any)=> {setLastWinStatus(row, event)}}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center p-1">
                                            <Col xs={6} className="text-end" style={{borderRight: '1px solid ' + widget["colorBG"]}}>
                                                <div className="text-muted">{props.t("Winnings")}</div>
                                            </Col>
                                            <Col xs={2}>
                                                <div className="text-muted">{widget["summary"]["winning"]}</div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center p-1">
                                            <Col xs={6} className="text-end" style={{borderRight: '1px solid ' + widget["colorBG"]}}>
                                                <div className="text-muted">{props.t("Average winnings")}</div>
                                            </Col>
                                            <Col xs={2}>
                                                <div className="text-muted">{widget["summary"]["current_average_winning"]}</div>
                                            </Col>
                                            <Col xs={4}>
                                                <Row>
                                                    <Input type="number" className="form-control text-center border-0"
                                                           data-strategyid={widget["id"]}
                                                           onChange={(e) =>
                                                               setAlertAverage(e)}
                                                           defaultValue={widget["summary"]["alert_average"]}/>
                                                </Row>
                                                <Row>
                                                    <Select
                                                        name={widget["id"]}
                                                        isSearchable={false}
                                                        className="select-status"
                                                        classNamePrefix="react-select"
                                                        placeholder={widget["summary"]["status_average"]}                                                        options={listStatus}
                                                        //value={1}
                                                        onChange={ (row: any, event: any)=> {setAverageStatus(row, event)}}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center p-1">
                                            <Col xs={6} className="text-end" style={{borderRight: '1px solid ' + widget["colorBG"]}}>
                                                <div className="text-muted">{ props.t("Total games")}</div>
                                            </Col>
                                            <Col xs={2}>
                                                <div className="text-muted">{widget["summary"]["total_game"]}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {
                loading && strategies.length == 0 &&
                <div className="position-absolute text-center h-100 bg-dark w-100 bg-white opacity-75 top-0 start-0">
                    <Spinner className="spinner-border text-info" style={{marginTop: '210px'}} role="status">
                    </Spinner>
                </div>
            }

            {strategyModal &&
                <SweetAlert
                    title=""
                    showCancel
                    showConfirm={false}
                    cancelBtnText={props.t("Cancel")}
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        //setGameModal(false)
                    }}
                    onCancel={() => {
                        setStrategyModal(false)
                    }}
                    style={{
                        width: "1200px",
                        marginTop: "0",
                        paddingTop: "0"
                    }}
                >
                {
                    loadingStrategyModal &&
                    <div className="position-absolute text-center h-100 bg-dark w-100 bg-white opacity-75 top-0 start-0">
                        <Spinner className="spinner-border text-info" style={{marginTop: '80px'}} role="status">
                        </Spinner>
                    </div>
                }
                {
                    strategySummary != [] &&
                    <Row className="p-1 font-size-20">
                        <Col>
                            <Row className="p-2">
                                <Col><strong>{props.t("Strategy") + ': '}</strong>
                                    {// @ts-ignore
                                    strategySummary["description"]}
                                </Col>
                            </Row>
                            <Row className="mb-3 p-1">
                                <Col><strong>{props.t("Last win") + ': '}</strong>
                                    {// @ts-ignore
                                    strategySummary["summary"]["current_last_win"]}
                                </Col>
                                <Col><strong>{props.t("Winnings") + ': '}</strong>
                                    {// @ts-ignore
                                    strategySummary["summary"]["winning"]}
                                </Col>

                                <Col><strong>{props.t("Average winnings") + ': '}</strong>
                                    {// @ts-ignore
                                    strategySummary["summary"]["current_average_winning"]}
                                </Col>
                                <Col><strong>{props.t("Total games") + ': '}</strong>
                                    {// @ts-ignore
                                    strategySummary["summary"]["total_game"]}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    !loadingStrategyModal && strategyByIdData.map( (data: any, key: number) =>
                        {

                        let dateTimeNow = new Date();

                        let dateTimeGame = moment(data.closed_at)
                        if(dateTimeNow.getTimezoneOffset() < 0){
                            dateTimeGame = moment(data.closed_at).add(Math.abs(dateTimeNow.getTimezoneOffset()), 'm');
                        }

                        if(dateTimeNow.getTimezoneOffset() > 0){
                            dateTimeGame = moment(data.closed_at).subtract(Math.abs(dateTimeNow.getTimezoneOffset()), 'm');
                        }

                        return <Row className={ 'hstack p-1 border-top ' +(data.win == 'YES' ? 'bg-success' : '')}
                                    key={'strategy_details_' + key}>
                                    <Col xs={1}>{key}</Col>
                                    <Col xs={2}>{(data.home_initial_odd != null ? data.home_initial_odd + ' ' : '') + data.home}</Col>
                                    <Col xs={1}>{data.goals_home}</Col>
                                    <Col xs={1}>{data.home_score}</Col>
                                    <Col xs={1}>{data.visitant_score}</Col>
                                    <Col xs={1}>{data.goals_away}</Col>
                                    <Col xs={2}>{(data.visitant_initial_odd != null ? data.visitant_initial_odd + ' ' : '') + data.visitant}</Col>
                                    <Col xs={1}>{data.win}</Col>
                                    <Col xs={2}>{dateTimeGame.format('YYYY-MM-DD HH:mm')}</Col>
                                </Row>
                        }
                    )
                }
                </SweetAlert>
            }
        </React.Fragment>
    );
};
export default withTranslation()(Summary);
