import { createSlice } from "@reduxjs/toolkit";
import {
    getMessages, getMessagesUnreadCount
} from "./thunk";

interface messagesStateType {
    messages: Array<any>;
    error?: string | object | null | undefined | unknown;
    loading: boolean;
    messagesUnreadCount: number;
}
export const initialState: messagesStateType = {
    messages: [],
    error: null,
    loading: false,
    messagesUnreadCount: 0
};

const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(getMessages.pending, (state: any, ) => {
            state.loading = true;
        });

        builder.addCase(getMessages.fulfilled, (state: any, action: any) => {
            state.messages = action.payload.data;
            state.loading = false;
        });

        builder.addCase(getMessages.rejected, (state, action) => {
            state.error = action.payload || null;
            state.loading = false;
        });

        builder.addCase(getMessagesUnreadCount.pending, (state: any, ) => {
            state.messagesUnreadCount = 0;
        });

        builder.addCase(getMessagesUnreadCount.fulfilled, (state: any, action: any) => {
            state.messagesUnreadCount = action.payload.data;
        });

        builder.addCase(getMessagesUnreadCount.rejected, (state, action) => {
            state.messagesUnreadCount = 0;
        });

    },
});

export default messagesSlice.reducer;
