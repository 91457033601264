import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

const ProfileMenu = (props: any) => {
  const { success, user } = useSelector((state: any) => ({
    success: state.profile.success,
    user: state.login.user
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");

    if (getAuthUser) {
        const obj = JSON.parse(getAuthUser);
        if(obj.user_name){
          setUsername(obj.user_name);
          setEmail(obj.email);
          setRole(obj.roles[0].role);
        }

    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
{/* {"user_name":"Carlos","email":"green_carloscosta@hotmail.com","phone":"+44 7850204805","points":"0","api_token":"1715606627-ATyReYg8mcHDkHSqCqyCnAYZ51Q6px603iYUnebdGxXtL79ZMeCIZCg7bXem","fcm_token":null,"profit":"4000.00","profit_day":"0.00","active":"0","receive_mobile_notification":"1","receive_notification_profit":"1","receive_notification_other":"1","receive_email":"0","auto_management":"0","auto_management_active":"0","message_pay":"0","lang":"pt","device_type":"ios","device_system_version":null,"device_identification":"56680064705955090200","app_version":"en","bet_booker":null,"stripe_id":"cus_Nd1nYbWNhLfpot","pm_type":null,"pm_last_four":null,"trial_ends_at":null,"roles":[{"id":1,"role":"Admin"}]}*/}
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title">{role}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{username}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">{email}</p>
          </div>
          <DropdownItem tag="a" href="/profile">
              <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
              <span className="align-middle">{props.t("Profile")}</span>
          </DropdownItem>
{/*          <DropdownItem tag="a" href="chat"><i className="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Messages")}</span></DropdownItem>
          <DropdownItem tag="a" href="pages-faqs"><i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Help")}</span></DropdownItem>
         */}
          <DropdownItem divider />
          <DropdownItem tag="a" href="/logout">
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Logout")}</span>
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
