import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import Summary from "./Summary";
import {withTranslation} from "react-i18next";

const strategy = (props: any) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Strategy")} | {props.t("Top Bet Football")}</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Strategy")} />

                    <Row>
                        <Summary />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(strategy);
