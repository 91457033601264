import React, { useState} from "react";
import {Container, Row, Col, Input, Label, Alert} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import MetaTags from "react-meta-tags";

//import images
import logoIcon from "../../assets/images/logo-icon.png";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, socialLogin} from "../../slices/Auth/Login/thunk";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {GoogleLogin} from "react-google-login";
import config from "../../config";
import * as cookie from '../../Cookies';
import * as constant from '../../constants/global';
import {decrypt} from "../../helpers/utility";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

//i18n
import { withTranslation } from "react-i18next";
import {useHistory} from "react-router";

import Sparkle from 'react-sparkle'

// @ts-ignore
import football_bg from '../../assets/videos/football_background_v6.mp4';

interface LoginProps {
    history: any;
}

const Login = (props: any, { history }: LoginProps) => {

  const dispatch = useDispatch();

  const historyRedirect = useHistory();

  const {error, loading, redirect, user} = useSelector((state: any) => ({
        error: state.login.error,
        loading: state.login.loading,
        redirect: state.login.redirect,
        user: state.login.user
  }));

  if (redirect && user) {
      cookie.set(constant.cookie_token, user.api_token);
      localStorage.setItem("authUser", JSON.stringify(user));
      window.location.href = "/games";
  }


  const cookie_remember_login = cookie.get(constant.cookie_remember_login) === 'OK'
  let emailDecrypt = ''
  let passwordDecrypt = ''

  if(cookie_remember_login){
      emailDecrypt = cookie.get(constant.cookie_email_login) ? decrypt(cookie.get(constant.cookie_email_login) ?? '') : ''
      passwordDecrypt = cookie.get(constant.cookie_password_login) ? decrypt(cookie.get(constant.cookie_password_login) ?? '') : ''
  }

  const [email, setEmail] = useState<string>(emailDecrypt);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState<string>( passwordDecrypt);
  const [passwordError, setPasswordError] = useState(false);
  const [remember, setRemember] = useState(cookie_remember_login);
  const [showPassword, setShowPassword] = useState( false);

  const handleValidSubmit = () => {

        const validEmail = constant.validEmailRegex.test(email);
        const validPassword = password !== ''

        if(validEmail && validPassword){
            dispatch(loginUser({'email' : email, 'password': password} ))
        }

        setEmailError(!validEmail)
        setPasswordError(!validPassword)
  };

  const signIn = (res: any, type: any) => {
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId
            };
            dispatch(socialLogin(postData, history, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                token: res.accessToken,
            };
            dispatch(socialLogin(postData, history, type));
        }
    };

  //handleGoogleLoginResponse
  const googleResponse = (response: Object) => {
        signIn(response, "google");
  };

  //handleFacebookLoginResponse
  const facebookResponse = (response: Object) => {
        signIn(response, "facebook");
  };

  const onClickRemember = () => {
      setRemember(!remember)
      if(remember){
          cookie.remove(constant.cookie_remember_login)
          cookie.remove(constant.cookie_email_login)
          cookie.remove(constant.cookie_password_login)
      }else{
          cookie.set(constant.cookie_remember_login, 'OK')
      }
  };

    return (
    <React.Fragment>
        <video className='video-bg' autoPlay loop muted>
            <source src={football_bg} type='video/mp4' />
        </video>
      <MetaTags>
        <title>Login | Top Bet Football</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
            <Sparkle flickerSpeed={'slow'} flicker={true} count={20} minSize={1}  maxSize={5} overflowPx={20} fadeOutSpeed={10} />
            <Container>
              <div className="d-flex flex-column min-vh-100 px-3">
                <Row className="justify-content-center my-auto">
                  <Col md={8} lg={6} xl={5} className="bg-white">
                    <div className="auth-language-dropdown"><LanguageDropdown /></div>
                    <div className="text-center py-5">
                        <div className="mb-1">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logoIcon} alt="" height="120" className="auth-logo-dark" />
                        </Link>
                      </div>
                      <div className="mb-4">
                        <h5>{props.t("Welcome Back!")}</h5>
                        <p>{props.t("Sign in to continue to Top Bet Football")}</p>
                        <p className="clr-red">{props.t("Please contact the admin to create your account")}</p>
                      </div>
                      {error && !loading ? <Alert color="danger">{error}</Alert> : null}
                      <div className="form-floating form-floating-custom mb-3">
                          <Input type="email" className={"form-control" + (emailError ? " is-invalid": "")}
                                 id="input-email" placeholder="Enter Email"
                                 onChange={(e) =>
                                     setEmail(e.target.value)}
                                 value={email}
                          />
                          <Label htmlFor="input-email">{ emailError ? props.t("Enter a valid Email") : "Email" }</Label>
                          <div className="form-floating-icon">
                              <i className="uil uil-users-alt"></i>
                          </div>
                      </div>
                      <div className="form-floating form-floating-custom mb-3">
                          <Input type={ showPassword ? "text" : "password" } className={"form-control" + (passwordError ? " is-invalid": "")} id="input-password"
                                 placeholder="Enter Password"
                                 onChange={(e) =>
                                     setPassword(e.target.value)}
                                 value={password}
                          />
                          <Label htmlFor="input-password">{ passwordError ? props.t("Enter a valid password") : "Password" }</Label>
                          <div className="form-floating-icon">
                            <i className="uil uil-padlock"></i>
                          </div>
                          <div className="form-floating-icon-right">
                              <i onClick={(e) =>
                                  setShowPassword(!showPassword)}
                                 className={ "cursor-pointer " + (showPassword ? "uil uil-eye-slash" : "uil uil-eye")}></i>
                          </div>
                      </div>

                      <div className="form-check form-check-info font-size-16">
                          <Input onClick={() => onClickRemember()}
                                 defaultChecked={remember} className="form-check-input"
                                 type="checkbox" id="remember-check" />
                          <Label className="form-check-label font-size-14"
                                 htmlFor="remember-check">
                              {props.t("Remember me")}
                          </Label>
                      </div>

                      <div className="mt-3">
                          <button disabled={loading} onClick={() => handleValidSubmit()} className="btn btn-success w-50 font-size-20"
                                  type="submit">{props.t("Log In")} { loading && <i className="fas fa-spinner fa-spin"></i>}
                          </button>
                      </div>

                      {/*<div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">{props.t("Sign in with")}</h5>
                          <ul className="list-inline">
                              <li className="list-inline-item">
                                  <FacebookLogin
                                      appId="" // Enter Your Facebook AppID
                                      autoLoad={false}
                                      callback={facebookResponse}
                                      render={(renderProps: any) => (
                                          <Link
                                              to="#"
                                              className="social-list-item bg-primary text-white border-primary"
                                              onClick={renderProps.onClick}
                                          >
                                              <i className="mdi mdi-facebook" />
                                          </Link>
                                      )}
                                  />
                              </li>

                              <li className="list-inline-item">
                                  <GoogleLogin
                                      clientId={
                                          config.google.CLIENT_ID // Enter Your Client ID
                                      }
                                      render={renderProps => (
                                          <Link
                                              to="#"
                                              className="social-list-item bg-danger text-white border-danger"
                                              onClick={renderProps.onClick}
                                          >
                                              <i className="mdi mdi-google" />
                                          </Link>
                                      )}
                                      onSuccess={googleResponse}
                                      onFailure={() => { }}
                                  />
                              </li>
                          </ul>
                      </div>*/}

    {/*                  <div className="mt-3">
                          <Link to="auth-resetpassword" className="text-muted text-decoration-underline">{props.t("Forgot your password?")}</Link>
                      </div>*/}

    {/*                  <div className="mt-3 text-center text-muted">
                        <p>{props.t("Don't have an account?")} <Link to="auth-signup" className="fw-medium text-decoration-underline"> {props.t("Sign Up")}</Link></p>
                      </div>*/}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Login));
