import React, {useEffect, useState} from "react";
import {Container, Row, Col, Input, Label, Alert} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import MetaTags from "react-meta-tags";
import parse from 'html-react-parser';

//import images
import logoIcon from "../../assets/images/logo-icon.png";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import { withTranslation } from "react-i18next";
import * as constant from "../../constants/global";
import {useDispatch, useSelector} from "react-redux";
import {registerUser} from "../../slices/Auth/Register/thunk";
import {registerUserDisplayForm} from "../../slices/Auth/Register/reducer";

const Register = (props: any) => {

    const dispatch = useDispatch();

    const {displayForm, isError, message, loading} = useSelector((state: any) => ({
        displayForm: state.register.displayForm,
        isError: state.register.isError,
        message: state.register.message,
        loading: state.register.loading
    }));

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState(false);
    const [password, setPassword] = useState<string>( '');
    const [passwordError, setPasswordError] = useState(false);
    const [messageError, setMessageError] = useState<string>( '');
    const [showPassword, setShowPassword] = useState( false);
    const [showMessageApi, setShowMessageApi] = useState( true);

    useEffect(() => {
        setMessageError('')
        setShowMessageApi(false)
        dispatch(registerUserDisplayForm());
    }, []);

    const handleValidSubmit = () => {

        const validEmail = constant.validEmailRegex.test(email);
        const validUsername = constant.validUserName.test(username);
        const validPassword = constant.validPasswordRegex.test(password);

        if(validEmail && validUsername && validPassword){
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            setMessageError('')
            setShowMessageApi(true)
            dispatch(registerUser({'email' : email, 'username': username, 'password': password, 'lang': lang}))
        }else{
            let buildMessageError = ''
            if(!validUsername){
                buildMessageError += "<p>&#x2022;" + props.t("Username must be between 5 and 20 characters, example: john56.") + "</p>";
            }
            if(!validPassword){
                buildMessageError += "<p>&#x2022;" + props.t("Password must be eight or more characters, at least one lower case, one upper case and one number.") + "</p>";
            }
            setMessageError(buildMessageError)
        }

        setEmailError(!validEmail)
        setUsernameError(!validUsername)
        setPasswordError(!validPassword)
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Register | Top Bet Football</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={5} className="bg-white">
                                <div className="auth-language-dropdown"><LanguageDropdown /></div>
                                <div className="text-center py-5">
                                    <div className="mb-1">
                                        <Link to="/games" className="d-block auth-logo">
                                            <img src={logoIcon} alt="" height="120" className="auth-logo-dark"/>
                                        </Link>
                                    </div>
                                    <div className="mb-4">
                                        <h5 className="">{props.t("Register Account")}</h5>
                                        <p>{props.t("Get your free Top Bet Football account now")}</p>
                                    </div>
                                    {message && !loading && showMessageApi ? <Alert color={ isError ? "danger" : "success"}>{parse(message)}</Alert> : null}
                                    {messageError ? <Alert className="text-start" color="danger">{parse(messageError)}</Alert> : null}
                                    {
                                        displayForm &&
                                        <div>
                                            <div className="form-floating form-floating-custom mb-3">
                                                <Input type="email" className={"form-control" + (emailError ? " is-invalid": "")}
                                                       id="input-email" placeholder="Enter Email"
                                                       onChange={(e) =>
                                                           setEmail(e.target.value)}
                                                       value={email}
                                                />
                                                <Label htmlFor="input-email">{ emailError ? props.t("Enter a valid Email") : "Email" }</Label>
                                                <div className="form-floating-icon">
                                                    <i className="uil uil-envelope-alt"></i>
                                                </div>
                                            </div>
                                            <div className="form-floating form-floating-custom mb-3">
                                                <Input type="text" className={"form-control" + (usernameError ? " is-invalid": "")}
                                                       id="input-username" placeholder="Enter Username"
                                                       onChange={(e) =>
                                                           setUsername(e.target.value)}
                                                       value={username}
                                                />
                                                <Label htmlFor="input-username">{ usernameError ? props.t("Enter a valid Username") : "Username" }</Label>
                                                <div className="form-floating-icon">
                                                    <i className="uil uil-user"></i>
                                                </div>
                                            </div>
                                            <div className="form-floating form-floating-custom mb-3">
                                                <Input type={ showPassword ? "text" : "password" } className={"form-control" + (passwordError ? " is-invalid": "")} id="input-password"
                                                       placeholder="Enter Password"
                                                       onChange={(e) =>
                                                           setPassword(e.target.value)}
                                                       value={password}
                                                />
                                                <Label htmlFor="input-password">{ passwordError ? props.t("Enter a valid password") : "Password" }</Label>
                                                <div className="form-floating-icon">
                                                    <i className="uil uil-padlock"></i>
                                                </div>
                                                <div className="form-floating-icon-right">
                                                    <i onClick={(e) =>
                                                        setShowPassword(!showPassword)}
                                                        className={ "cursor-pointer " + (showPassword ? "uil uil-eye-slash" : "uil uil-eye")}></i>
                                                </div>
                                            </div>

                                            <div className="text-start">
                                                <p>{props.t("By registering you agree to the Top Bet Football")} <Link to="/terms-conditions" className="text-decoration-underline">{props.t("Terms & Conditions")}</Link></p>
                                            </div>

                                            <div className="mt-3">
                                                <button disabled={loading} onClick={() => handleValidSubmit()} className="btn btn-success w-50 font-size-20"
                                                        type="submit">{props.t("Register")} { loading && <i className="fas fa-spinner fa-spin"></i>}
                                                </button>
                                            </div>
                                            <div className="mt-3 text-center">
                                                <p>{props.t("Already have an account?")}<Link to="auth-signin"
                                                                                 className="fw-medium text-decoration-underline"> {props.t("Log In")}</Link>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !displayForm &&
                                        <div className="mt-3 text-center">
                                        <p><Link to="auth-signin"
                                        className="fw-medium text-decoration-underline"> {props.t("Back to Log In")}</Link>
                            </p>
                    </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );

};
export default withTranslation()(withRouter(Register));

