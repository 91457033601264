import {createAsyncThunk} from "@reduxjs/toolkit";
import * as url from "../../../helpers/url_helper";
import {ApiCore} from "../../../helpers/api_helper";
import {GET_ACTIVATE_SUBSCRIPTION} from "../../../helpers/url_helper";

const api = new ApiCore();

export const editProfile = (user: any) => async (dispatch: any) => {

};

export const getSubscription = createAsyncThunk(
    "user/subscription",
    async () => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.get(
                url.GET_SUBSCRIPTION + '?lang='+lang
            );
        } catch (error) {
            return error;
        }
    }
);

export const getActivateSubscription = createAsyncThunk(
    "user/activate-subscription",
    async () => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.get(
                url.GET_ACTIVATE_SUBSCRIPTION + '?lang='+lang
            );
        } catch (error) {
            return error;
        }
    }
);

export const getCancelSubscription = createAsyncThunk(
    "user/cancel-subscription",
    async () => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.get(
                url.GET_CANCEL_SUBSCRIPTION + '?lang='+lang
            );
        } catch (error) {
            return error;
        }
    }
);

export const updateUserDetails = createAsyncThunk(
    "user/update-user-details",
    async (params: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.UPDATE_USER_DETAILS + '?lang='+lang,
                params
            );
        } catch (error) {
            return error;
        }
    }
);
