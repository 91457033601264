import * as process from "process";

const config = {
    firebase : {
          apiKey: process.env.REACT_APP_APIKEY,
          authDomain: process.env.REACT_APP_AUTHDOMAIN,
          databaseURL: process.env.REACT_APP_DATABASEURL,
          projectId: process.env.REACT_APP_PROJECTID,
          storageBucket: process.env.REACT_APP_STORAGEBUCKET,
          messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
          appId: process.env.REACT_APP_APPID,
          measurementId: process.env.REACT_APP_MEASUREMENTID,
    },
    google: {
        API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
        CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
        SECRET: process.env.REACT_APP_GOOGLE_SECRET_KEY,
    },
    facebook: {
        APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    },
    cookie:{
        PREFIX: process.env.REACT_APP_COOKIE_PREFIX || 'TBF_',
        EXPIRE_DAYS: parseInt(process.env.REACT_APP_COOKIE_EXPIRE_DAYS??'30')
    },
    api: {
        url: process.env.REACT_APP_API || 'https://topbetfootball.com/api/v1.0.0'
    }
}

export default config;
