import { Redirect } from "react-router-dom";

//Strategy
import Strategy from "../pages/Strategy";

//Analytics
import Analytics from "../pages/Analytics";

//profile
import UserProfile from "../pages/Authentication/user-profile"

//  Inner Authentication
import Login from "../pages/AuthenticationInner/Login";
import Register from "../pages/AuthenticationInner/Register";
import ResetPassword from "../pages/AuthenticationInner/ResetPassword";

import TermsConditions from "../pages/AuthenticationInner/TermsConditions";

//Authentication pages
import Logout from "../pages/Authentication/Logout";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";

import GamesTable from "../pages/Tables/GamesTable";

//Strategy
import Messages from "../pages/Messages";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [

  { path: "/games", component: GamesTable },
  { path: "/strategy", component: Strategy },
  { path: "/analytics", component: Analytics },
  { path: "/messages", component: Messages },
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: () => <Redirect to="/games" /> }
];

const authRoutes: Array<RouteProps> = [

    //Routes done by me
    { path: "/logout", component: Logout },
    { path: "/auth-signin", component: Login },
    { path: "/auth-signup", component: Register },
    { path: "/auth-resetpassword", component: ResetPassword },

    //utility page
    { path: "/pages-comingsoon", component: PagesComingsoon },
    { path: "/pages-maintenance", component: PageMaintenance },
    { path: "/terms-conditions", component: TermsConditions }

];

export { userRoutes, authRoutes };
