//Include Both Helper File with needed methods
import {getFirebaseBackend} from "../../../helpers/firebase_helper";

import * as url from "../../../helpers/url_helper";
import {ApiCore} from "../../../helpers/api_helper";
import {createAsyncThunk} from "@reduxjs/toolkit";

const api = new ApiCore();

export const loginUser = createAsyncThunk(
    "user/register",
    async (user: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.POST_LOGIN + '?lang='+lang,
                {
                    email: user.email,
                    password: user.password
                }
            );
        } catch (error) {
            return error;
        }
    });


//to be removed
export const socialLogin = (data: any, history: any, type: any) => async (dispatch: any) => {
    try {
        let response;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            let fireBaseBackend = getFirebaseBackend();

            response = fireBaseBackend.socialLoginUser(
                data,
                type
            );

        } else {
            //response = postSocialLogin(data);
        }

        const socialdata = await response;

        if (socialdata) {
            localStorage.setItem("authUser", JSON.stringify(socialdata));
            history.push("/dashboard");
        }

    } catch (error) {
        //dispatch(apiError(error));
    }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");
        history.push("/auth-signin");

        const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
        return api.create(
            url.POST_LOGOUT + '?lang='+lang,
            {
            }
        );
    } catch (error) {
        return error;
    }
};
