import { createSlice } from "@reduxjs/toolkit";
import {loginUser} from "./thunk";

interface LoginStateType {
    userProfile: object,
    error?: string | object | null | undefined | unknown,
    user?: any,
    loading?: boolean,
    redirect?: boolean
}
export const initialState: LoginStateType = {
    userProfile: {},
    error: '',
    user: '',
    loading: false,
    redirect: false
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loginUser.pending, (state: any) => {
            state.loading = true
        });
        builder.addCase(loginUser.fulfilled, (state: any, action) => {
            state.user = action.payload.data
            state.loading = false
            state.redirect = true
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.error = action.error.message || null;
            state.loading = false;
        });
    }
});

export default loginSlice.reducer;
