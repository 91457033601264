import {createAsyncThunk} from "@reduxjs/toolkit";
import * as url from "../../../helpers/url_helper";
import {ApiCore} from "../../../helpers/api_helper";

const api = new ApiCore();

export const registerUser = createAsyncThunk(
    "user/register",
    async (user: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.POST_REGISTER,
                {
                    email: user.email,
                    username: user.username,
                    password: user.password,
                    lang: lang
                }
            );
        } catch (error) {
            return error;
        }
    });
