import React, {useEffect, useState} from "react";
import { Container, Row, Col, Input, Label, Alert } from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import MetaTags from "react-meta-tags";

//import images
import logoIcon from "../../assets/images/logo-icon.png";
import * as constant from "../../constants/global";
import {useDispatch, useSelector} from "react-redux";
import {userForgetPassword} from "../../slices/Auth/Forgetpwd/thunk";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

//i18n
import { withTranslation } from "react-i18next";

const ResetPassword = (props: any) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false);
    const [displayError, setDisplayError] = useState(true);

    useEffect(() => {
        setDisplayError(false)
    }, []);

    const {forgetError, forgetLoading} = useSelector((state: any) => ({
        forgetError: state.forgetPassword.forgetError,
        forgetLoading: state.forgetPassword.forgetLoading
    }));

    const handleValidSubmit = () => {

        const validEmail = constant.validEmailRegex.test(email);
        if(validEmail){
            setDisplayError(true)
            dispatch(userForgetPassword({'email' : email}))
        }
        setEmailError(!validEmail)
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Reset Password | Top Bet Football</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={5} className={"bg-white"}>
                                <div className="auth-language-dropdown"><LanguageDropdown /></div>
                                <div className="text-center py-5">
                                    <div className="mb-1">
                                        <Link to="/dashboard" className="d-block auth-logo">
                                            <img src={logoIcon} alt="" height="120" className="auth-logo-dark" />
                                        </Link>
                                    </div>
                                    <div className="text-muted mb-4">
                                        <h5 className="">{props.t("Reset Password")}</h5>
                                        <p>{props.t("Re-Password with Top Bet Football")}</p>
                                    </div>
                                    {forgetError && !forgetLoading && displayError? <Alert color="danger">{forgetError}</Alert> : null}
                                    <Alert color="success" className="text-center mb-4" role="alert">
                                        {props.t("Enter your Email and instructions will be sent to your email box!")}
                                    </Alert>
                                    <div className="form-floating form-floating-custom mb-3">
                                        <Input type="email" className={"form-control" + (emailError ? " is-invalid": "")}
                                               id="input-email"
                                               placeholder="Enter Email"
                                               onChange={(e) =>
                                                   setEmail(e.target.value)}
                                               value={email}/>
                                        <Label htmlFor="input-email">{ emailError ? props.t("Enter a valid Email") : "Email" }</Label>
                                        <div className="form-floating-icon">
                                            <i className="uil uil-envelope-alt"></i>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <button disabled={forgetLoading} onClick={() => handleValidSubmit()} className="btn btn-info w-75 font-size-20"
                                                type="submit">{props.t("Send Request")} { forgetLoading && <i className="fas fa-spinner fa-spin"></i>}
                                        </button>
                                    </div>
                                    <div className="mt-4">
                                        <Link to="auth-signin" className="text-muted text-decoration-underline">{props.t("Back to Log In")}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(ResetPassword));
