import React, {useEffect} from "react";

import {
    changeLayout,
    changeLayoutWidth,
    changeLayoutPosition,
    changelayoutMode,
    changeTopbarTheme,
    changeSidebarType,
    changeSidebarTheme,
    changeDesktopNotification,
    changeDesktopGoalPrediction, changeDesktopGoalPredictionPeriod
} from "../../slices/thunks";

//constants
import {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import {withTranslation} from "react-i18next";
import * as constant from "../../constants/global";

const RightSidebar = (props: any) => {
  const dispatch = useDispatch();

  const {
    layoutType,
    layoutMode,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    desktopNotification,
    desktopGoalPrediction,
    desktopGoalPrediction_15,
    desktopGoalPrediction_15_30,
    desktopGoalPrediction_30_45,
    desktopGoalPrediction_45_60,
    desktopGoalPrediction_60_75,
    desktopGoalPrediction_75
  } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
    layoutMode: state.Layout.layoutMode,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    desktopNotification: state.Layout.desktopNotification == 'active',
    desktopGoalPrediction: state.Layout.desktopGoalPrediction == 'active',
    desktopGoalPrediction_15: state.Layout.desktopGoalPrediction_15 == 'active',
    desktopGoalPrediction_15_30: state.Layout.desktopGoalPrediction_15_30 == 'active',
    desktopGoalPrediction_30_45: state.Layout.desktopGoalPrediction_30_45 == 'active',
    desktopGoalPrediction_45_60: state.Layout.desktopGoalPrediction_45_60 == 'active',
    desktopGoalPrediction_60_75: state.Layout.desktopGoalPrediction_60_75 == 'active',
    desktopGoalPrediction_75: state.Layout.desktopGoalPrediction_75 == 'active'
  }));

    useEffect(() => {
        const desktop_notification = localStorage.getItem(constant.desktop_notification);

        if(desktop_notification !==  null){
            dispatch(changeDesktopNotification(desktop_notification));
        }else{
            dispatch(changeDesktopNotification('not-active'));
        }

        const desktop_goal_prediction = localStorage.getItem(constant.desktop_goal_prediction);

        if(desktop_goal_prediction !==  null){
            dispatch(changeDesktopGoalPrediction(desktop_goal_prediction));
        }else{
            dispatch(changeDesktopGoalPrediction('not-active'));
        }

        const desktop_goal_prediction_15 = localStorage.getItem(constant.desktop_goal_prediction + '_15');

        if(desktop_goal_prediction_15 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_15, '15'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '15'));
        }

        const desktop_goal_prediction_15_30 = localStorage.getItem(constant.desktop_goal_prediction + '_15_30');

        if(desktop_goal_prediction_15_30 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_15_30, '15_30'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '15_30'));
        }

        const desktop_goal_prediction_30_45 = localStorage.getItem(constant.desktop_goal_prediction + '_30_45');

        if(desktop_goal_prediction_30_45 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_30_45, '30_45'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '30_45'));
        }

        const desktop_goal_prediction_45_60 = localStorage.getItem(constant.desktop_goal_prediction + '_45_60');

        if(desktop_goal_prediction_45_60 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_45_60, '45_60'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '45_60'));
        }

        const desktop_goal_prediction_60_75 = localStorage.getItem(constant.desktop_goal_prediction + '_60_75');

        if(desktop_goal_prediction_60_75 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_60_75, '60_75'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '60_75'));
        }

        const desktop_goal_prediction_75 = localStorage.getItem(constant.desktop_goal_prediction + '_75');

        if(desktop_goal_prediction_75 !==  null){
            dispatch(changeDesktopGoalPredictionPeriod(desktop_goal_prediction_75, '75'));
        }else{
            dispatch(changeDesktopGoalPredictionPeriod('not-active', '75'));
        }

    }, [])

  return (
    <React.Fragment>
      <div>
        <SimpleBar className="vh-100">

          <div className="rightbar-title d-flex align-items-center bg-dark p-3">
            <h5 className="m-0 me-2 text-white">{props.t("Settings")}</h5>
            <Link
              to="#"
              onClick={props.onClose}
              className="right-bar-toggle ms-auto"
            >
              <i className="mdi mdi-close noti-icon"></i>
            </Link>
          </div>

          <hr className="m-0" />

          <div className="p-4">
            <h6 className="mb-3">{props.t("Desktop notifications")}</h6>
            <div className="form-check form-check-inline">
                  <input
                      type="radio"
                      id="radioDesktopNotificationOff"
                      name="desktopNotification"
                      className="form-check-input"
                      checked={!desktopNotification}
                      onChange={e => {
                            dispatch(changeDesktopNotification('not-active'));
                      }}
                  />
                  <label htmlFor="radioDesktopNotificationOff" className="form-check-label">
                      {props.t("Off")}
                  </label>
              </div>
            {"   "}
            <div className="form-check form-check-inline">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="desktopNotification"
                      id="radioDesktopNotificationOn"
                      checked={desktopNotification}
                      onChange={e => {
                          dispatch(changeDesktopNotification('active'));
                      }}
                  />
                  <label className="form-check-label" htmlFor="radioDesktopNotificationOn">
                      {props.t("On")}
                  </label>
              </div>

          </div>

          <div className="p-4">
            <h6 className="mb-3">{props.t("Goal prediction")}</h6>
            <div className="form-check form-check-inline">
                <input
                    type="radio"
                    id="radioDesktopGoalPredictionOff"
                    name="desktopGoalPrediction"
                    className="form-check-input"
                    checked={!desktopGoalPrediction}
                    onChange={e => {
                        dispatch(changeDesktopGoalPrediction('not-active'));
                    }}
                />
                <label htmlFor="radioDesktopGoalPredictionOff" className="form-check-label">
                    {props.t("Off")}
                </label>
            </div>
            {"   "}
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="desktopGoalPrediction"
                    id="radioDesktopGoalPredictionOn"
                    checked={desktopGoalPrediction}
                    onChange={e => {
                        dispatch(changeDesktopGoalPrediction('active'));
                    }}
                />
                <label className="form-check-label" htmlFor="radioDesktopGoalPredictionOn">
                    {props.t("On")}
                </label>
            </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Less than 15 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_15_Off"
                          name="desktopGoalPrediction_15"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_15}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '15'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_15_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_15"
                          id="radioDesktopGoalPrediction_15_On"
                          checked={desktopGoalPrediction_15}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '15'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_15_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Between 16 and 30 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_15_30_Off"
                          name="desktopGoalPrediction_15_30"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_15_30}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '15_30'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_15_30_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_15_30"
                          id="radioDesktopGoalPrediction_15_30_On"
                          checked={desktopGoalPrediction_15_30}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '15_30'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_15_30_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Between 31 and 45 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_30_45_Off"
                          name="desktopGoalPrediction_30_45"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_30_45}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '30_45'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_30_45_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_30_45"
                          id="radioDesktopGoalPrediction_30_45_On"
                          checked={desktopGoalPrediction_30_45}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '30_45'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_30_45_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Between 46 and 60 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_45_60_Off"
                          name="desktopGoalPrediction_45_60"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_45_60}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '45_60'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_45_60_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_45_60"
                          id="radioDesktopGoalPrediction_45_60_On"
                          checked={desktopGoalPrediction_45_60}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '45_60'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_45_60_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Between 61 and 75 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_60_75_Off"
                          name="desktopGoalPrediction_60_75"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_60_75}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '60_75'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_60_75_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_60_75"
                          id="radioDesktopGoalPrediction_60_75_On"
                          checked={desktopGoalPrediction_60_75}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '60_75'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_60_75_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
              <div className="p-4">
                  <h6 className="mb-3">{props.t("Greater than 76 minutes")}</h6>
                  <div className="form-check form-check-inline">
                      <input
                          type="radio"
                          id="radioDesktopGoalPrediction_75_Off"
                          name="desktopGoalPrediction_75"
                          className="form-check-input"
                          checked={!desktopGoalPrediction_75}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('not-active', '75'));
                          }}
                      />
                      <label htmlFor="radioDesktopGoalPrediction_75_Off" className="form-check-label">
                          {props.t("Off")}
                      </label>
                  </div>
                  {"   "}
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="desktopGoalPrediction_75"
                          id="radioDesktopGoalPrediction_75_On"
                          checked={desktopGoalPrediction_75}
                          onChange={e => {
                              dispatch(changeDesktopGoalPredictionPeriod('active', '75'));
                          }}
                      />
                      <label className="form-check-label" htmlFor="radioDesktopGoalPrediction_75_On">
                          {props.t("On")}
                      </label>
                  </div>
              </div>
          </div>

        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};
export default withTranslation()(RightSidebar);
