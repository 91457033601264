import { createSlice } from "@reduxjs/toolkit";
import {registerUser} from "./thunk";

interface RegisterStateType {
    isError?: boolean,
    message: any,
    loading: any,
    displayForm: boolean
}
export const initialState: RegisterStateType = {
    isError: false,
    message: null,
    loading: false,
    displayForm: true
};

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        registerUserDisplayForm(state){
            state.displayForm = true;
        }
    },
    extraReducers: builder => {
        builder.addCase(registerUser.pending, (state: any) => {
            state.displayForm = true;
            state.loading = true;
        });
        builder.addCase(registerUser.fulfilled, (state: any, action) => {
            state.displayForm = false;
            state.isError = false;
            state.message = action.payload.message;
            state.loading = false;
        });
        builder.addCase(registerUser.rejected, (state, action) => {
            state.displayForm = true;
            state.isError = true;
            state.message = action.error.message || null;
            state.loading = false;
        });
    }
});

export const {
    registerUserDisplayForm
} = registerSlice.actions

export default registerSlice.reducer;
