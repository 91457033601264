import {getCookie, removeCookie, setCookie} from 'typescript-cookie'
import config from "../src/config";

export function set(parameter: string | number, val: any){
    let now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000 * config.cookie.EXPIRE_DAYS);
    const cookie_options = {path: '/', expires: now}
    setCookie(config.cookie.PREFIX + parameter, val, cookie_options);
}

export function get(parameter: string | number){
    return getCookie(config.cookie.PREFIX + parameter);
}

export function remove(parameter: string | number){
    removeCookie(config.cookie.PREFIX + parameter);
}
