import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {Container, Row, Col, Card, CardBody, Button, Alert, Input, Label, Spinner} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import {
    editProfile,
    getActivateSubscription,
    getSubscription,
    getCancelSubscription,
    updateUserDetails
} from "../../slices/thunks";
import {withTranslation} from "react-i18next";
import * as constant from "../../constants/global";
import parse from "html-react-parser";

const UserProfile = (props: any) => {
  const dispatch = useDispatch();

  const { loadingSubscription, subscription, loadingActivateOrCancelSubscription, cancelSubscriptionStatus,
      isErrorUpdateUserDetails, messageUpdateUserDetails, loadingUpdateUserDetails} = useSelector((state: any) => ({
      loadingSubscription: state.profile.loadingSubscription,
      subscription: state.profile.subscription,
      loadingActivateOrCancelSubscription: state.profile.loadingActivateOrCancelSubscription,
      cancelSubscriptionStatus: state.profile.cancelSubscriptionStatus,
      loadingUpdateUserDetails: state.profile.loadingUpdateUserDetails,
      messageUpdateUserDetails: state.profile.messageUpdateUserDetails,
      isErrorUpdateUserDetails: state.profile.isErrorUpdateUserDetails
  }));

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [messageError, setMessageError] = useState<string>( '');
  const [showCurrentPassword, setShowCurrentPassword] = useState( false);
  const [showNewPassword, setShowNewPassword] = useState( false);
  const [showMessageApi, setShowMessageApi] = useState( false);


    const updateSubscriptionTimeout = () => {
      dispatch(getSubscription());
  }

  const subscribeOrCancel = (option: string) => {
      if(option === 'subscribe'){
        dispatch(getActivateSubscription());
      }else{
        dispatch(getCancelSubscription());
      }
  }

  const updateUserDetailsBtn = () => {

      const validUsername = constant.validUserName.test(username);
      const validCurrentPassword = constant.validPasswordRegex.test(currentPassword);

      let validNewPassword = true
      if(newPassword){
          validNewPassword = constant.validPasswordRegex.test(newPassword);
      }

      if(validUsername && validCurrentPassword && validNewPassword && currentPassword != newPassword){
          setMessageError('')
          setShowMessageApi(true)
          dispatch(updateUserDetails({user_name: username, password: currentPassword, new_password: newPassword}));
      }else{
          setShowMessageApi(false)
          let buildMessageError = ''
          if(!validUsername){
              buildMessageError += props.t("Username must be between 5 and 20 characters, example: john56.");
          }

          if(!validCurrentPassword){
              buildMessageError += (buildMessageError != '' ? '<br>' : '') + props.t("Enter your current password to update your details.");
          }

          if(!validNewPassword){
              buildMessageError += (buildMessageError != '' ? '<br>' : '') + props.t("New password must be eight or more characters, at least one lower case, one upper case and one number.");
          }

          if(currentPassword == newPassword){
              buildMessageError += (buildMessageError != '' ? '<br>' : '') + props.t("New password must be different.");
          }

          setMessageError(buildMessageError)
      }
  }

  useEffect(() => {
      const authUser: any = localStorage.getItem("authUser");

      if (authUser) {
        const obj = JSON.parse(authUser);
        setUsername(obj.user_name);
      }

      dispatch(getSubscription());
      const timer = setInterval(updateSubscriptionTimeout, 30000);
      return () => clearInterval(timer);

  }, []);

  function handleValidSubmit(event: any, values: any) {
      dispatch(editProfile(values));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Profile")} | {props.t("Top Bet Football")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={props.t("User")} breadcrumbItem={props.t("Profile")} />
          <Card>
            <CardBody>
              <Row>
                  <Col xs={12} lg={6}>
                        {
                          cancelSubscriptionStatus != '' &&
                          <Row className="p-2">
                              <Alert className="text-center"
                                     color={cancelSubscriptionStatus == "failed" ? "danger" : "success"}>{ cancelSubscriptionStatus == 'failed' ? props.t("Something went wrong please contact the administrator.") : props.t("Subscription successfully canceled.")}</Alert>
                          </Row>
                        }
                        {
                          subscription &&
                          <React.Fragment>
                              <Row>
                                  <Col>
                                      <div className="text-center mb-4">
                                          <h5>{props.t("Subscription status")}</h5>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      {props.t("Active")} : {subscription && subscription.active}
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      {props.t("Expired date")} : {subscription && subscription.expire_date}
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      {props.t("Monthly price")} : {subscription && subscription.amount}
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      {props.t("App code")} : {subscription.app_code}
                                  </Col>
                              </Row>
                              <Row>
                                  <Col>
                                      <div className="text-center mt-4">
                                          <Button disabled={loadingActivateOrCancelSubscription} type="button" onClick={() => subscribeOrCancel(subscription.subscribe ? "subscribe" : "cancel")} color={subscription.subscribe ? "success" : "danger"}>
                                              {props.t(subscription.subscribe ? "Subscribe" : "Cancel")}
                                          </Button>
                                      </div>
                                  </Col>
                              </Row>
                          </React.Fragment>
                        }
                        {
                          !subscription &&
                          <React.Fragment>
                              <Row>
                                  <Col>
                                      <div className="text-center mb-4">
                                          <h5>{props.t("Subscription status")}</h5>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="text-center">
                                      <Spinner className="spinner-border text-info" style={{marginTop: '50px'}}  role="status">
                                      </Spinner>
                                  </Col>
                              </Row>
                          </React.Fragment>
                        }
                </Col>
                <Col className="border-start" xs={12} lg={6}>
                  <Row>
                    <Col>
                        {messageUpdateUserDetails && !loadingUpdateUserDetails && showMessageApi ?
                            <Alert color={ isErrorUpdateUserDetails ? "danger" : "success"}>{parse(messageUpdateUserDetails)}</Alert> : null}
                        {messageError ? <Alert className="text-start" color="danger">{parse(messageError)}</Alert> : null}
                        <div className="form-floating form-floating-custom mb-3">
                            <Input type="text" className={"form-control" + (usernameError ? " is-invalid": "")}
                                   id="input-username" placeholder="Enter Username"
                                   onChange={(e) =>
                                       setUsername(e.target.value)}
                                   value={username}
                            />
                            <Label htmlFor="input-username">{ props.t(usernameError ? "Enter a valid username" : "Username") }</Label>
                        </div>
                        <div className="form-floating form-floating-custom mb-3">
                            <Input type={ showCurrentPassword ? "text" : "password" } className={"form-control" + (passwordError ? " is-invalid": "")}
                                   placeholder="Enter Password"
                                   onChange={(e) =>
                                       setCurrentPassword(e.target.value)}
                                   value={currentPassword}
                            />
                            <Label htmlFor="input-password">{ props.t(passwordError ? "Enter a valid password" : "Current password") }</Label>
                            <div className="form-floating-icon">
                                <i className="uil uil-padlock"></i>
                            </div>
                            <div className="form-floating-icon-right">
                                <i onClick={(e) =>
                                    setShowCurrentPassword(!showCurrentPassword)}
                                   className={ "cursor-pointer " + (showCurrentPassword ? "uil uil-eye-slash" : "uil uil-eye")}></i>
                            </div>
                        </div>
                        <div className="form-floating form-floating-custom mb-3">
                            <Input type={ showNewPassword ? "text" : "password" } className={"form-control" + (newPasswordError ? " is-invalid": "")}
                                   placeholder="Enter Password"
                                   onChange={(e) =>
                                       setNewPassword(e.target.value)}
                                   value={newPassword}
                            />
                            <Label htmlFor="input-password">{ props.t( newPasswordError ? "Enter a valid new password" : "New password") }</Label>
                            <div className="form-floating-icon">
                                <i className="uil uil-padlock"></i>
                            </div>
                            <div className="form-floating-icon-right">
                                <i onClick={(e) =>
                                    setShowNewPassword(!showNewPassword)}
                                   className={ "cursor-pointer " + (showNewPassword ? "uil uil-eye-slash" : "uil uil-eye")}></i>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                          <Button onClick={ () => updateUserDetailsBtn()} type="submit" color="info">
                            {props.t("Update user details")}
                          </Button>
                        </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(UserProfile));
