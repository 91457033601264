import React from 'react';
import { useState, useEffect } from 'react';

const Timer = (props: any) => {

    let [time, setTime] = useState(props.initialSeconds);

    const timeGameUpdateTableTimeout = () =>{
        setTime(time++)
        setTimeout(timeGameUpdateTableTimeout, 1000);
    }

    useEffect(() => {
        timeGameUpdateTableTimeout()
        return () => {
            setTime(0);
        };
    }, []);


    return (
        <div>
            {props.halfTime === 'HT' ? '45:00' : parseInt(String(time / 60)) + ':' + ((time%60)<10 ? '0' + (time%60) : (time%60)) }
        </div>
    );
};

export default Timer;
