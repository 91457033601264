import axios from "axios";
import * as cookie from '../Cookies';
//import all constants
import * as constant from '../constants/global';
import config from "../config";

//pass new generated access token here
const token = cookie.get(constant.cookie_token);

//apply base url for axios
const API_URL = config.api.url;

axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    return response.data ? response.data : response;
}, function (error) {
    if(error.response.status == 401){
        localStorage.removeItem("authUser");
        window.location.href = "/auth-signin";
        return;
    }

    return Promise.reject(error.response.data);
});

class ApiCore {
  /**
   * Fetches data from given url
   */
  get = (url : string, params?: {}) => {
      return axios.get(API_URL + url);
  };

  /**
   * post given data to url
   */
  create = (url : string, data : {}) => {
      return axios.post(API_URL + url, data)
  };

  /**
   * Updates data
   */
  update = (url: string, data:{}) => {
      return axios.put(API_URL + url, data);
  };

  /**
   * Delete
   */
  delete = (url : string, data?:{}) => {
      return axios.delete(API_URL + url);
  };
};

export {ApiCore};
