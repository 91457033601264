import React from "react";

import {Switch, BrowserRouter as Router, Route} from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import Error404 from "./pages/Error/Error404";

import useScreenSize from './useScreenSize';
import * as constant from "./constants/global";


const App = () => {

    const screenSize = useScreenSize();

    const body = document.body;

    if(screenSize.width < 991){
        localStorage.setItem(constant.toggle_menu, 'sm')
        body.setAttribute("data-sidebar-size", 'sm');
        //body.classList.toggle("sidebar-enable");
    }else{
        localStorage.setItem(constant.toggle_menu, '')
        body.setAttribute("data-sidebar-size", '');
    }

    return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
    );
};

export default App;
