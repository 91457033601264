import { createSlice } from "@reduxjs/toolkit";
import {getGames, getTeam} from "./thunk";

interface gamesStateType {
    teams: Array<any>;
    games: Array<any>;
    error?: string | object | null | undefined | unknown;
    errorGameMarket?: string | object | null | undefined | unknown;
    loading: boolean;
    requestedId: string;
    loadingTeam: boolean;
}
export const initialState: gamesStateType = {
    teams: [],
    games: [],
    error: null,
    errorGameMarket: null,
    loading: false,
    requestedId: '',
    loadingTeam: false
};

const gamesSlice = createSlice({
    name: "games",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(getGames.pending, (state: any, action: any) => {
            state.loading = true;
            state.requestedId = action.meta.requestId
        });

        builder.addCase(getGames.fulfilled, (state: any, action: any) => {

            if( state.requestedId == action.meta.requestId) {
                state.isOpenGames = action.payload.data.isOpenGames
                state.games = action.payload.data.results;
                state.loading = false;
            }

        });

        builder.addCase(getGames.rejected, (state, action) => {
            state.error = action.payload || null;
            state.loading = false;
        });

        builder.addCase(getTeam.pending, (state: any) => {
            state.loadingTeam = true
        });

        builder.addCase(getTeam.fulfilled, (state: any, action: any) => {
            state.teams = action.payload.data
            state.loadingTeam = false
        });

        builder.addCase(getTeam.rejected, (state: any) => {
            state.loadingTeam = false
        });
    }
});

export default gamesSlice.reducer;
